.draggedItem {
    border: "1px solid red";
    z-index: 2000;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

// .closeIcon {
//     cursor: pointer;
//     position: absolute;
//     top: -13px;
//     right: -17px;
//     font-size: 24px;
//     color: $danger;
// }

.adz_icons {
    position: absolute;
    bottom: 0;
    left: 0; 
    width: 100%; 
    font-size: 15px; 
    background-color: #dbdbdb82;
    color: #828282;
    cursor: pointer;
    // text-align: center;
}

.adz_icon:hover {
    color: #303030;
}